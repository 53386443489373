<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <b-card-code>
                <validation-observer ref="simpleRules">
                  <form @submit.prevent="submitForm">
                    <b-row>
                      <b-col md="4">
                        <b-form-group>
                          <label>Assign To</label>
                          <v-select
                            v-model="assignto"
                            label="name"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            placeholder="None"
                            :options="branchOptions"
                            @input="getEmployee()"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        md="4"
                        v-if="assignto &&
                          (assignto.name == 'Channel Partner' ||
                          assignto.name == 'Franchise' ||
                          assignto.name == 'Employee')
                        "
                      >
                        <b-form-group>
                          <label>Select {{ assignto.name }}</label>
                          <v-select
                            v-model="employee"
                            label="fullname"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            placeholder="None"
                            :options="employeeoption"
                          >
                           <template #option="{ fullname, profile_image, username }">
                              <b-avatar  :src="getprofileImage(profile_image)" />
                              <span class="font-weight-bolder"> {{ fullname }}  ({{ username }})</span
                              >
                            </template>
                          </v-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                          <b-form-group>
                            <label>Lead Category</label>
                            <v-select
                              v-model="leadCategory"
                              label="name"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              placeholder="None"
                              :options="categoryOptions"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form-group>
                            <label>Select Date</label>
                            <flat-pickr
                              class="form-control"
                              value=""
                              :config="{
                                dateFormat: 'd/m/Y',
                                mode: 'range',
                              }"
                              placeholder="Select Date"
                              style="background-color: transparent"
                              @input="getdata($event)"
                            />
                          </b-form-group>
                        </b-col>
                    </b-row>
                    <div class="d-flex justify-content-end">
                      <b-button
                        variant="primary"
                        class="ml-2"
                        @click="searchData"
                        :disabled='loading'
                      >
                        <feather-icon icon="SearchIcon" class="mr-50" />
                        <span class="align-middle">Search</span>
                      </b-button>
                    </div>
                  </form>
                </validation-observer>
              </b-card-code>
              <report-table :data="data" :columns="fields" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportTable from "../../../components/ReportTable.vue";
import axios from '@/components/axios';
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Form from "@/components/form/Form.vue";
import moment from 'moment'
import {
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BAvatar,

  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  components: {
    ReportTable,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
  BAvatar,
    GoodTableColumnSearch,
  },
  data() {
    return {
      branchOptions: [
        { name: "All", id: "all" },
        { name: "Channel Partner", id: "channelpartner" },
        { name: "Franchise", id: "franchise" },
        { name: "Employee", id: "employee" },
        { name: "Admin", id: "admin" },
      ],
      loading:false,
      assignto: "",
      employee: "",
      employeeoption: [],
      subemployee: "",
      subemployeeoption: [],
      assigntoOptions: [],
      startdate: "",
      enddate: "",
      result: "",
      data: "",
      categoryOptions:[],
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      fields: [
        {
          field: "leadsource",
          label: "Lead Source",
        },
        {
          field: "subsource",
          label: "Sub Source",
        },
        {
          field: "leadcategory",
          label: "Lead Category",
        },
        {
          field: "fullname",
          label: "Name",
        },
        {
          field: "leaddate",
          label: "Lead Date",
        },
        {
          field: "mobile",
          label: "Mobile",
        },
        {
          field: "email",
          label: "Email",
        },
        {
           field: "city",
           label: "City",
         },
         {
           field: "state",
           label: "State",
         },

        {
          field: "address",
          label: "Address",
        },
        {
          field: "assignto",
          label: "Assign To",
        },
        {
          field: "leadcategory",
          label: "Lead Category",
        },
        // {
        //   field: "lead_category",
        //   label: "Lead Category",
          // filterOptions: { enabled: true, placeholder: "Search Lead Category" },
        // },
      ],
      allUser: [],
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      leadCategory:''
    };
  },
  mounted() {
    this.getCategory()
  },
  methods: {
     getprofileImage(image) {
      if (image !== undefined && image !== null && image !== "") {
        let test = image.includes(this.imagePath)
          ? image
          : this.imagePath + image;
        return test;
      }
      return image;
    },
    getdata(selectedDates) {
      this.result = selectedDates.split(" to ");
      this.startdate = this.result.length >0 ?this.result[0]:'';
      this.enddate = this.result.length == 2 ?this.result[1]:'';
    },
    async searchData() {
      const data = {
        assignto: this.assignto ?this.assignto.id:'',
        user_id: this.employee ?this.employee.id:'',
        leadCategory: this.leadCategory ? this.leadCategory.id : '',
        startdate: this.startdate? this.startdate:'',
        enddate: this.enddate? this.enddate:'',
      };
      this.loading=true
      await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: JSON.stringify(data),
        url: `${this.baseApi}/leadreport`,
      }).then((response) => {
        this.data = response.data.data;

        this.data.map((item) => {
          item.leadsource = item.leadsource.length>0? item.leadsource[0].name:''
          item.subsource = item.subsource_data.length>0? item.subsource_data[0].name:''
          item.leadcategory = item.leadcategory.length>0? item.leadcategory[0].name:''
          item.assignto = item.assignto.length > 0 ? item.assignto[0].fullname : ''
          item.leaddate = item.leaddate? moment(item.leaddate).format('DD/MM/yyyy') : ''
        });
      }).finally(()=>{
        this.loading = false
      })
    },
    async getEmployee() {
      this.employee = "";
      let url
      if(this.assignto){

      if (this.assignto.name == "Channel Partner") {
        url='getChannelpartner'
      } else if (this.assignto.name == "Franchise") {
        url = 'getFranchise'
      } else if (this.assignto.name == "Employee") {
        url = 'getEmployee'
      }
        if (this.assignto && this.assignto.name !== 'Admin' && this.assignto.name !== 'All' )  {
          const requestOptions = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.accessToken}`,
            },
            url: `${this.baseApi}/${url}`,
          };
          await axios(requestOptions)
            .then((response) => {
              this.employeeoption = response.data.data;
            })
            .catch((error) => console.log(error, "error"));
        }
      }

    },
    async getCategory() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/leadcategory?pagesize=9999`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.categoryOptions = response.data.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
